import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react'
import './webcomponents'
import './index.scss'

const isLegacy = document.createElement('script').noModule === undefined

if (isLegacy) {
  import(/* webpackChunkName: 'legacy-support' */ './polyfills.js').then(() =>
    console.info('Legacy browsers support enabled')
  )
}

import('./main.js').then()

// THIS SCRIPT WILL LOAD ONLY WHEN THE EXPERIENCE EDITOR IS ENABLED
if (isExperienceEditorActive()) {
  import('./experience-editor-patches/index.js').then(() =>
    console.info('EXPERIENCE EDITOR PATCHES LOADED')
  )
}

window.onscroll = function () {
  scrollFunction()
}

function scrollFunction() {
  var element = document.getElementById('navigation-id')
  var logolist = document.getElementById('logo-items')
  var navigationMenuMobile = document.getElementById('hamburgerMenuMain')
  if (element && logolist && !navigationMenuMobile) {
    if (
      document.body.scrollTop > 10 ||
      document.documentElement.scrollTop > 10
    ) {
      document.querySelector('header').classList.add('shrink-content')
      element.classList.add('headroom--not-top')
      logolist.classList.add('shrink-content')
    } else {
      element.classList.remove('headroom--not-top')
      document.querySelector('header').classList.remove('shrink-content')
      logolist.classList.remove('shrink-content')
    }
  }
}
